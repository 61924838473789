<template>
	<w-flex d-flex shrink>
		<w-btn flat icon="tune" mini small @click="customizationDialog = true">{{ $t('projects.user_preferences') }}</w-btn>
		<w-dialog v-model="customizationDialog" max-width="600px" :title="$t('projects.user_preferences')">
			<w-layout column>
				<w-flex shrink>
					<v-subheader v-text="$t('projects.content_display_mode') + ' :'" />
					<v-radio-group v-model="contentDisplayPreference">
						<v-radio color="primary" :label="$t('projects.content_display_mode_table')" value="table" />
						<v-radio color="primary" :label="$t('projects.content_display_mode_treeview')" value="treeview" />
					</v-radio-group>
				</w-flex>
			</w-layout>
		</w-dialog>
		<w-btn v-if="isAccountantAdmin" icon="add" mini small @click="addServices()">{{ $t('projects.add_new_services') }}</w-btn>
		<w-btn color="" flat :icon="expand ? 'keyboard_arrow_up' : 'keyboard_arrow_down'" mini small @click="switchExpansionState()"></w-btn>
	</w-flex>
</template>

<script>
import { mapState } from 'vuex'
import ProjectsManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'

export default {
	name: 'CustomersManagerOffersActions',
	mixins: [ProjectsManagerModuleGuard],
	props: {
		expand: {
			default: true,
			required: false,
			type: Boolean
		}
	},
	data: function () {
		return {
			customizationDialog: false,
			displayPreferencesStorageKey: 'customer-offers__content-display'
		}
	},
	computed: {
		...mapState({
			isAccountantAdmin: state => state.accountingFirm.selected.isAccountantAdmin
		}),
		contentDisplayPreference: {
			get: function () {
				const contentDisplayMode = this.$store.state.customers.projects.display.content
				if (contentDisplayMode) {
					return contentDisplayMode
				}
				const storedValue = localStorage.getItem(this.displayPreferencesStorageKey)
				let result
				if (storedValue && ['table', 'treeview'].includes(storedValue)) {
					if (['table', 'treeview'].includes(storedValue)) {
						localStorage.setItem(this.displayPreferencesStorageKey, storedValue)
						return this.setContentDisplayMode(storedValue)
					}
					result = storedValue
				} else {
					if (['table', 'treeview'].includes('table')) {
						localStorage.setItem(this.displayPreferencesStorageKey, 'table')
						return this.setContentDisplayMode('table')
					}
					result = 'table'
				}
				return result
			},
			set: function (val) {
				if (['table', 'treeview'].includes(val)) {
					localStorage.setItem(this.displayPreferencesStorageKey, val)
					this.setContentDisplayMode(val)
				}
			}
		}
	},
	methods: {
		addServices: async function () {
			this.appService.goTo('customer-services-wizard')
		},
		switchExpansionState: function () {
			this.$emit('update:expand', !this.expand)
		},
		setContentDisplayMode: function (value) {
			this.$store.dispatch('customers/projects/setContentDisplayMode', value)
		}
	}
}
</script>
